<template>
  <div>
    <v-form v-model="valid" class="login-form">
      <div>
        <label>Email Address</label>
        <v-text-field
          outlined
          v-model="email"
          dense
          color="#0582D2"
          :rules="emailRules"
        />
      </div>

      <div>
        <label>Password</label>
        <v-text-field
          v-model="password"
          outlined
          dense
          color="#0582D2"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          :rules="passwordRules"
          class="input-group--focused"
          @click:append="show = !show"
        />
      </div>
    </v-form>

    <div class="my-3">
      <PrimaryButton
        :loading="loading"
        :color="BRANDCOLOR"
        :block="true"
        :large="true"
        @click="handleLogin"
        :disabled="!valid"
      >
        Login
      </PrimaryButton>
    </div>
  </div>
</template>


<script>
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import { BRANDCOLOR } from "@/constants/constants";
import authService from "@/services/Auth/authService";

export default {
  components: { PrimaryButton },
  data: () => ({
    email: "",
    password: "",
    loading: false,
    emailRules: [
      (v) => !!v || "Email address is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [(v) => !!v || "Password is required"],
    show: false,
    valid: true,
    BRANDCOLOR,
  }),

  methods: {
    async handleLogin() {
      try {
        this.loading = true;
        const response = await authService.login({
          email: this.email,
          password: this.password,
          
        });
        this.$store.dispatch("auth/setUser", response);
        await this.$store.dispatch("auth/fetchAdminDetails",{id: response?.data?.id});
        this.loading = false;
        window.location.href = "/dashboard";
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>