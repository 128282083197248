<template>
  <DashboardLayout>
    <div>
      <!-- Status Filter Dropdown -->
      <div class="my-6 d-flex justify-space-between filter-container">
        <v-select
          v-model="selectedStatus"
          :items="statuses"
          label="Select"
          dense
          outlined
          @change="fetchDataBasedOnStatus"
        ></v-select>
      </div>

      <!-- Dynamic Header for SMS or Email -->
      <div class="my-6 d-flex justify-space-between">
        <h2 class="admin-table-title">
          {{ selectedStatus === "sms" ? "Daily Bulk SMS" : "Bulk Emails" }}
          ({{ dailytexts && dailytexts?.length }})
        </h2>
        <div>
          <PrimaryButton
            v-if="selectedStatus === 'sms'"
            @click="addNew"
            :color="BRANDCOLOR"
            :outlined="true"
            class="mr-2"
          >
            <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon> Send Bulk SMS
          </PrimaryButton>
          <PrimaryButton
            v-if="selectedStatus === 'email'"
            @click="addBulkEMAIL"
            :color="BRANDCOLOR"
            :outlined="true"
          >
            <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon> Send Bulk Email
          </PrimaryButton>
        </div>
      </div>

      <!-- Admin Table for SMS or Email -->
      <AdminTable :columns="tableColumns" :loading="loading">
        <tr class="table-row" v-for="(text, i) in dailytexts" :key="i">
          <!-- <td>{{ text?.id }}</td> -->
          <td>{{ text?.scheduled_date }}</td>
          <td >
            {{ convertUtcToLocalHour(text?.scheduled_hour) }}
          </td>
           <td v-if="selectedStatus === 'sms'">{{ text?.immediate_schedule }}</td>
          <td>{{ text?.compaign_name }}</td>
         
          <td>
            <v-btn
              v-if="selectedStatus === 'sms'"
              @click="showSmsStatus(text)"
              color="primary"
            >
              Detail
            </v-btn>
            <v-btn
              v-if="selectedStatus === 'email'"
              @click="showEmailStatus(text)"
              color="primary"
            >
              Detail
            </v-btn>
          </td>
        </tr>
      </AdminTable>

      <!-- Dialogs for Bulk SMS and Email -->
      <v-dialog v-model="addnewbulksms" max-width="600px" :key="modalkey">
        <AddNewBulkSMS
          ref="addNewBulkSMS"
          @closeModal="addnewbulksms = !addnewbulksms"
          :loading="loader"
          @postBulkSMS="postBulkEmail"
        />
      </v-dialog>

      <v-dialog v-model="addnewtext" max-width="600px" :key="modalkey">
        <AddNewText
          ref="addNewText"
          @closeModal="addnewtext = !addnewtext"
          :loading="loader"
          @postDailyText="postDailyBulkSms"
        />
      </v-dialog>

      <div>
        <v-dialog max-width="580px" v-model="showdetails">
          <ContentDetail
            :contentData="contentData"
            :status="selectedStatus"
            @closeModal="closeModal"
          />
        </v-dialog>
      </div>

      <!-- Dialog for Editing -->
      <v-dialog v-model="editnewtext" max-width="430px" :key="modalkey">
        <EditText
          ref="editText"
          :content="dailytext.content"
          @closeModal="editnewtext = !editnewtext"
          @postDailyText="handleUpdateDailyText"
          :loading="menuloading"
        />
      </v-dialog>

      <!-- Overlay Loader -->
      <overlay-loader :loading="menuloading" :description="'Loading...'" />
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
// import DailyTextMenu from "@/components/DailyText/DailyTextMenu.vue";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";

import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import { BRANDCOLOR } from "@/constants/constants";
import AddNewText from "@/elements/Modals/AddNewText.vue";
import AddNewBulkSMS from "@/elements/Modals/AddNewBulkSMS.vue";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import dailytextService from "@/services/DailyText/dailytextService";
import EditText from "@/elements/Modals/EditText.vue";
import ContentDetail from "@/elements/Modals/ContentDetail.vue";

export default {
  components: {
    DashboardLayout,
    AdminTable,
    ContentDetail,
    // DailyTextMenu,
    OverlayLoader,

    PrimaryButton,
    AddNewText,
    AddNewBulkSMS,
    EditText,
  },
  data: () => ({
    COLUMNS_SMS: [
      "Schedule Date",
      "Schedule Time",
       "Send Right Now",
      "Campaign Name",     
      "Status",
    ],
    COLUMNS_EMAIL: ["Schedule Date", "Schedule Time", "Campaign Name", "Status"],
    menuloading: false,
    BRANDCOLOR,
    contentData: "",
    addnewtext: false,
    addnewbulksms: false,
    editnewtext: false,
    showdetails: false,
    dailytext: {},
    modalkey: 0,
    loader: false,
    selectedStatus: "email",
    statuses: ["sms", "email"], // Dropdown options
  }),

  computed: {
    ...mapState("dailytext", {
      loading: (state) => state.loading,
      dailytexts: (state) => state.dailytexts,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
    tableColumns() {
      return this.selectedStatus === "sms" ? this.COLUMNS_SMS : this.COLUMNS_EMAIL;
    },
  },

  created() {
    this.fetchDataBasedOnStatus(); // Fetch SMS by default
  },

  methods: {
    convertUtcToLocalHour(utcTime) {
      if(!utcTime){
        return
      }
      // Create a date object for today's date
      const date = new Date();

      // Split the `utcTime` string into hours and minutes
      const [utcHour, utcMinutes] = utcTime.split(":").map(Number);

      // Set the UTC hours and minutes on the date object
      date.setUTCHours(utcHour, utcMinutes, 0, 0);

      // Get the local hours and minutes from the date object
      const localHour = date.getHours();
      const localMinutes = date.getMinutes();

      // Determine AM/PM
      const period = localHour >= 12 ? "PM" : "AM";
      const formattedHour = localHour % 12 || 12; // Convert 24-hour to 12-hour format
      const formattedMinutes = localMinutes.toString().padStart(2, "0"); // Pad single digits with 0

      // Return the formatted local time in HH:MM AM/PM format
      return `${formattedHour}:${formattedMinutes} ${period}`;
    },

    fetchDataBasedOnStatus() {
      // Fetch data based on the selected status
      if (this.selectedStatus === "sms") {
        this.$store.dispatch(
          "dailytext/fetchDailyTexts",
          "patient/api/bulk-sms-messages"
        ); // Fetch SMS data
      } else {
        this.$store.dispatch("dailytext/fetchDailyTexts", `patient/api/email-messages`); // Fetch Email data
      }
    },
    addNew() {
      this.modalkey++;
      this.addnewtext = true;
    },

    showDetails(contentData) {
      this.contentData = { ...contentData }; // Ensure a fresh copy of the article is used
      this.showdetails = true;
    },
    showEmailStatus(contentData) {
      this.$router.push(`/daily-text/detail/${contentData.id}`);
    },
    showSmsStatus(contentData) {
      this.$router.push(`/sms-detail/${contentData.id}`);
    },
    addBulkEMAIL() {
      this.$router.push("/bulk-email");
      // this.modalkey++;
      // this.addnewbulksms = true;
    },
    setLoading(value) {
      this.menuloading = value;
    },

    async postDailyBulkSms(sms, date, time, country, campaignName, immediate) {
      try {
        this.loader = true;

        const [hourMinuteString, period] = time.split(" ");

        // Split hour and minute (e.g., "12:30" -> ["12", "30"])
        const [localHour, minute] = hourMinuteString.split(":").map(Number);

        const { hour: utcHour, minute: utcMinute } = this.convertLocalToUtcHour(
          localHour,
          minute,
          period
        );

        // Step 1: Convert local to UTC hour
        // const hour = this.convertLocalToUtcHour(localHour, period);
        const response = await dailytextService.postDailyBulkSMS(
          `patient/api/sms-messages`,
          {
            message: sms,
            service_name: country, //PISI or YD
            scheduled_date: date,
            scheduled_hour: utcHour ? `${utcHour}:${utcMinute}` : "", // 24 hours
            compaign_name: campaignName,
            immediate_schedule: immediate,
          }
        );
        handleSuccess(response.data.message);
        this.loader = false;
        this.$store.dispatch(
          "dailytext/fetchDailyTexts",
          "patient/api/bulk-sms-messages"
        );
        this.addnewtext = false;
      } catch (error) {
        handleError(error.message);
        this.loader = false;
      }
    },
    // convertLocalToUtcHour(localHour, amPm) {
    //   console.log("amPm",amPm)
    //   // Convert 12-hour format to 24-hour format
    //   let hour24 = amPm === "PM" ? (localHour % 12) + 12 : localHour % 12;

    //   // Create a date object for today with the local hour
    //   const date = new Date();
    //   date.setHours(hour24, 0, 0, 0); // Set the local hour

    //   // Get the UTC hours from the date object
    //   const utcHour = date.getUTCHours();
    //   return utcHour;
    // },

    convertLocalToUtcHour(localHour, minute, amPm) {
      // Convert 12-hour format to 24-hour format
      let hour24 = amPm === "PM" ? (localHour % 12) + 12 : localHour % 12;

      // Create a date object for today with the local hour and minute
      const date = new Date();
      date.setHours(hour24, minute, 0, 0); // Set the local hour and minute

      // Get the UTC hours and minutes from the date object
      const utcHour = date.getUTCHours();
      const utcMinute = date.getUTCMinutes();

      return { hour: utcHour, minute: utcMinute };
    },

    // async postBulkEmail(sms, date, time, emailSubject,compaign) {
    //   let hour = time.split(" ");

    //   try {
    //     this.loading = true;
    //     const response = await dailytextService.postBulkEMAIL(
    //       `patient/api/email-messages`,
    //       {
    //         email_subject: emailSubject,
    //         email_body: sms,
    //         scheduled_date: date,
    //         scheduled_hour: Number(hour[0]),
    //         compaign_name:compaign
    //       }
    //     );
    //     handleSuccess(response.data.message);
    //     this.loading = false;
    //  this.$store.dispatch("dailytext/fetchDailyTexts", `patient/api/email-messages`);
    //     this.addnewbulksms = false;
    //   } catch (error) {
    //     handleError(error.message);
    //     this.loading = false;
    //   }
    // },
    // async postBulkEmail(sms, date, time, emailSubject, campaign) {
    //   try {
    //     this.loader = true;

    //     // Split the time and convert it to a 24-hour format

    //     const [hourString, period] = time.split(" ");

    //     // Parse the hour as a number
    //     const localHour = parseInt(hourString, 10);

    //     // Step 1: Convert local to UTC hour
    //     const hour = this.convertLocalToUtcHour(localHour, period);

    //     const response = await dailytextService.postBulkEMAIL(
    //       `patient/api/email-messages`,
    //       {
    //         email_subject: emailSubject,
    //         email_body: sms,
    //         scheduled_date: date, // Using the UTC date-time here
    //         scheduled_hour: hour, // If you still need the hour in 24-hour format, you can pass it, or remove this if not needed
    //         compaign_name: campaign,
    //       }
    //     );

    //     handleSuccess(response.data.message);
    //     this.loader = false;
    //     this.$store.dispatch("dailytext/fetchDailyTexts", `patient/api/email-messages`);
    //     this.addnewbulksms = false;
    //   } catch (error) {
    //     handleError(error.message);
    //     this.loader = false;
    //   }
    // },

    updateDailyText(e) {
      this.modalkey++;
      this.dailytext = e;
      this.editnewtext = !this.editnewtext;
    },
    async handleUpdateDailyText(sms) {
      try {
        this.menuloading = true;
        const response = await dailytextService.updateDailyText(
          `/daily-health-content/update/` + this.dailytext.id,
          { content: sms }
        );
        handleSuccess(response.data.message);
        this.menuloading = false;
        this.$store.dispatch("dailytext/fetchDailyTexts");
        this.editnewtext = false;
      } catch (error) {
        handleError(error.message);
        this.menuloading = false;
      }
    },
  },
};
</script>

<style scoped>
.admin-table-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
}

.table-row td {
  padding: 6x;
  white-space: nowrap;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.no-article-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Increase the font size */
  height: 100px; /* Optional: adjust height to ensure vertical centering */
  font-weight: bold; /* Optional: make the text bold */
  color: #555; /* Optional: change the text color */
}

.filter-container {
  margin-bottom: 16px;
  max-width: 200px;
}
</style>
