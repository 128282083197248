<template>
  <DashboardLayout>
    <div>
      <AdminDashboardCard
        :title="`Welcome back!👋🏻 Here’s what’s going on, ${user?.firstName}`"
        :description="'Manage and keep track of all that happens on Betacare'"
      />
    </div>

    <div class="admin-stat-cards">
      <StatCard
        v-for="(stat, i) in STATS"
        :key="i"
        :title="stat.title"
        :icon="stat.icon"
        :number="stat.number"
        :color="'#E9EEF9'"
      />
    </div>

    <div class="admin-subscription-cards">
      <SubscriptionCard
        v-for="(subcription, i) in SUBSCRIPTIONS"
        :title="subcription.title"
        :number="subcription.number"
        :networks="subcription.networks"
        :key="i"
      />
    </div>

    <v-card class="pa-6 mb-6 rounded-lg">
      <h2 class="admin-table-title font-weight-bold">Patients Report</h2>
      <line-chart :datacollection="monthlysubscribers"></line-chart>
    </v-card>

    <div>
      <h2 class="admin-table-title">Patient’s Sign-up Log (10)</h2>
      <AdminTable :columns="columns">
        <tr class="table-row" v-for="(row, i) in admintabledata" :key="i">
          <td>{{ row.date }}</td>
          <td>{{ row.email }}</td>
          <td>{{ row.phone }}</td>
          <td>{{ row.time }}</td>
        </tr>
      </AdminTable>
    </div>

    <div class="admin-graph-flex">
      <div>
        <div class="my-2 d-flex justify-space-between">
          <h2 class="mt-2 admin-table-title">Monthly subscribers</h2>
          <select :items="NETWORKS">
            <option v-for="(network, i) in NETWORKS" :key="i">
              {{ network }} users
            </option>
          </select>
        </div>
        <LineChart :datacollection="monthlysubscribers" />
      </div>

      <div>
        <div class="my-2 d-flex justify-space-between">
          <h2 class="mt-2 admin-table-title">Churn rate</h2>
          <select :items="NETWORKS">
            <option v-for="(network, i) in NETWORKS" :key="i">
              {{ network }} users
            </option>
          </select>
        </div>
        <LineChart :datacollection="subscriberdata" />
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminDashboardCard from "@/components/Dashboard/AdminDashboardCard.vue";
import StatCard from "@/elements/Cards/StatCard.vue";
import SubscriptionCard from "@/elements/Cards/SubscriptionCard.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import LineChart from "@/elements/Graph/LineChart.vue";
import {
  ADMINTABLECOLUMNS,
  ADMINTABLEROWS,
  STATS,
  SUBSCRIBERS,
  NETWORKS,
  ADMINDETAILS,
} from "@/constants/constants";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  components: {
    DashboardLayout,
    AdminDashboardCard,
    StatCard,
    SubscriptionCard,
    AdminTable,
    LineChart,
  },
  data: () => ({
    STATS,
    SUBSCRIPTIONS: SUBSCRIBERS,
    NETWORKS,
    columns: ADMINTABLECOLUMNS,
    admintabledata: ADMINTABLEROWS,
    user: ADMINDETAILS,
  }),

  computed: {
    ...mapState("graph", {
      monthlysubscribers: (state) => state.monthlysubscribers,
      subscriberdata: (state) => state.subscriberdata,
    }),
  },
};
</script>