<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <!-- <v-btn @click.native="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      > -->
    </div>

    <div>
      <h2 class="my-3">Doctor's Details</h2>

      <div class="d-flex justify-space-between">
        <p>First name</p>
        <p>{{ doctor?.first_name }}</p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Last name</p>
        <p>{{ doctor?.last_name }}</p>
      </div>
      <div class="d-flex justify-space-between">
        <p>E-mail</p>
        <p>{{ doctor.email }}</p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Phone number</p>
        <p>{{ doctor?.phone_number }}</p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Qualification</p>
        <p>{{ doctor?.qualification }}</p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Practice number</p>
        <p>{{ doctor?.practice_number }}</p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Area of specialization</p>
        <p>{{ doctor?.speciality }}</p>
      </div>
      <!-- <div class="d-flex justify-space-between">
        <p>Valid ID Card</p>
        <p>{{ doctor?.valid_id_card }}</p>
      </div>
      <div class="d-flex justify-space-between">
        <p>Valid ID License</p>
        <p>{{ doctor?.valid_license }}</p>
      </div>
      <div class="d-flex justify-space-between">
        <p>Curriculum Vitae</p>
        <p>{{ doctor?.curriculum_vitae }}</p>
      </div> -->
    </div>

    <div class="my-6 button-grid">
  
      <PrimaryButton
        :color="'#FFE6E6'"
        :class="'red--text'"
        :large="true"
        @click.native="rejectRequest"
      >
        Reject
      </PrimaryButton>
      <PrimaryButton
        :large="true"
        :color="'#E9EEF9'"
        :class="'blue--text'"
        @click.native="acceptRequest"
      >
        Accept
      </PrimaryButton>
    </div>
    <overlay-loader :loading="loading" />
  </v-card>
</template>
<script>
import { handleError, handleSuccess } from "@/utils/handleResponse";
// import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import requestService from "@/services/Requests/requestService";
import OverlayLoader from "../Loader/OverlayLoader.vue";
export default {
  components: {
    PrimaryButton,
    OverlayLoader,
  },
  props: {
    doctor: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async acceptRequest() {
      this.loading = true;
      try {
        // const response = await requestService.updateRequest(
        //   "/admin/accept-reject-doc-signup-req",
        //   {
        //     action: "accept",
        //     doctorId: this.doctor.id,
        //     reason: "request approved",
        //   }
        // );
        const response = await requestService.updateRequest(
          `doctor/regstatus/${this.doctor.id}`,
          {
            action: "Accept",
            doctor_reg_status: "ACCEPT",
          }
        );
        console.log("response==>", response.data?.message);
        handleSuccess(response.data?.message);
        this.loading = false;
        this.$emit("closeModal");
        this.$store.dispatch("request/fetchDoctorRequests");
      } catch (error) {
        handleError(error.message);
      }
      this.loading = false;
    },
    async rejectRequest() {
      this.loading = true;
      try {
        const response = await requestService.updateRequest(
          `doctor/regstatus/${this.doctor.id}`,
          {
            action: "Reject",
            doctor_reg_status: "REJECT",
          }
        );
        handleSuccess(response?.data?.message);
        this.loading = false;
        this.$emit("closeModal");
        this.$store.dispatch("request/fetchDoctorRequests");
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },
  },
};
</script>
