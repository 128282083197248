<template>
  <div>
    <v-navigation-drawer
      fixed
      absolute
      :permanent="!isMobile"
      :temporary="isMobile"
      left
      v-model="drawer"
      width="234px"
    >
      <div class="mt-6 mx-6">
        <router-link to="/dashboard"><Logo /> </router-link>
      </div>

      <div class="navmenu-list">
        <v-list>
          <v-list-item-group v-for="(item, i) in menu" :key="i">
            <v-list-item :to="item.link" link :active-class="'blueBg'" class="menu-item">
              <v-list-item-icon style="margin-right: 20px">
                <v-icon v-if="!item.type">{{ item.icon }}</v-icon>
                <Icon :name="item.icon" :color="DEFAULTICONCOLOR" />
              </v-list-item-icon>

              <v-list-item-content :to="item.link">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <hr class="my-2" />
          </v-list-item-group>
        </v-list>
      </div>

      <div class="navmenu-btn">
        <div>
          <div>
            <PrimaryButton
              @click="$router.push('/settings')"
              :large="true"
              :outlined="true"
              :color="BRANDCOLOR"
            >
              <v-icon small class="mr-1">mdi-cog</v-icon> Settings
            </PrimaryButton>
          </div>

          <div>
            <PrimaryButton @click="handleLogout" :large="true" :color="BRANDCOLOR">
              <img class="mr-2" src="@/assets/logout.svg" />Logout
            </PrimaryButton>
          </div>
        </div>
      </div>

      <v-dialog v-model="logout" max-width="500px">
        <LogoutModal @closeModal="logout = !logout" />
      </v-dialog>

      <v-overlay v-if="loading" class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
        <p>Logging out...</p>
      </v-overlay>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Logo from "@/assets/icons/Logo.vue";
import Icon from "@/assets/icons/Icons.vue";
import { BRANDCOLOR, DEFAULTICONCOLOR } from "@/constants/constants";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import LogoutModal from "@/elements/Modals/LogoutModal.vue";

export default {
  name: "NavBar",
  components: {
    Icon,
    Logo,
    PrimaryButton,
    LogoutModal,
  },
  props: {
    isMobile: {
      type: Boolean,
    },
    user: {
      type: Object,
    },
  },
  data: () => ({
    DEFAULTICONCOLOR,
    BRANDCOLOR,
    menu: [
      {
        title: "Dashboard",
        icon: "mdi-view-grid-outline",
        link: "/dashboard",
      },
      {
        title: "Doctor's Request",
        icon: "doctor-request",
        link: "/doctor-request",
        type: "custom-icon",
      },
      {
        title: "Consultation",
        icon: "doctor-request",
        link: "/doctor-consultations",
        type: "custom-icon",
      },
      { title: "Send message", icon: "mdi-calculator", link: "/daily-text" },
      { title: "Popup Event", icon: "mdi-calculator", link: "/popup-event" },
      {
        title: "Sub Admins",
        icon: "users",
        link: "/subadmins",
        type: "custom-icon",
      },

      {
        title: "Activities",
        icon: "activities",
        link: "/activities",
        type: "custom-icon",
      },

      // {
      //   title: "Tracker",
      //   icon: "pie-chart",
      //   link: "/tracker",
      //   type: "custom-icon",
      // },
      {
        title: "Tracker Labs",
        icon: "pie-chart",
        link: "/tracker-lab-test",
        type: "custom-icon",
      },
      {
        title: "Ambulance",
        icon: "activities",
        link: "/ambulance-user-request",
        type: "custom-icon",
      },
      {
        title: "Create Article",
        icon: "activities",
        link: "/article",
        type: "custom-icon",
      },
      {
        title: "All Article",
        icon: "activities",
        link: "/all-article",
        type: "custom-icon",
      },
    ],
    drawer: false,
    group: null,
    userData: "",
    name: "",
    image: "",
    loading: false,
    open: false,
    value: false,
    logout: false,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    showDrawer() {
      this.drawer = !this.drawer;
    },
    handleLogout() {
      this.logout = !this.logout;
    },
    showValue() {
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
@import "./Navbar.css";
</style>
