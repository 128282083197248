<!-- <template>
  <DashboardLayout>
    <div>
      <h2 class="admin-table-title my-2">Doctor’s Sign-up Log {{doctorrequests?.length}}</h2>
      <AdminTable :columns="COLUMNS" :loading="loading">
        <tr class="table-row" v-for="(request, i) in doctorrequests" :key="i">
          <td>{{ formatDate(request?.created_date) }}</td>
          <td>{{ request?.email }}</td>
          <td>{{ request?.phone_number}}</td>
          <td>{{ formatTime(request?.created_date) }}</td>
          <td>
            <primary-button
              @click="showDetails(request)"
              :color="'#BACBEC'"
              :class="'blue--text'"
              >View</primary-button
            >
          </td>
        </tr>
      </AdminTable>

      <div class="my-4" v-if='doctorrequests.length>0'>
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <div>
      <v-dialog max-width="580px" v-model="showdetails" :key="modalkey" >
        <DoctorRequest
          :doctor="doctor"
          @closeModal="closeModal"
        />
      </v-dialog>
      
    
    </div>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template>



<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import DoctorRequest from "@/elements/Modals/DoctorRequest.vue";
export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
    PrimaryButton,
    DoctorRequest,
  },
  data: () => ({
    // COLUMNS: ["Date", "E-mail", "Phone Number", "Time","Valid ID Card","Valid ID License","Curriculum Vitae","Action"],
    COLUMNS: ["Date", "E-mail", "Phone Number", "Time","Action"],
    menuloading: false,
    showdetails: false,
    doctor: {},
     modalkey: 0,
  }),

  computed: {
    ...mapState("request", {
      loading: (state) => state.loading,
      doctorrequests: (state) => state.doctorrequests,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
  },

  created() {
    this.$store.dispatch("request/fetchDoctorRequests");
  },

  methods: {
    // setLoading(value) {
    //   this.menuloading = value;
    // },
    setPagination(e) {
      this.$store.dispatch("request/handlePagination", e);
    },

    showDetails(e) {
      this.doctor ={...e};
      this.showdetails = true;
       this.modalkey++;
      // this.$store.dispatch("request/fetchSingleDoctorRequest", e.id);
    },
    
    closeModal() {
      this.showdetails = false;
      this.doctor = {}; // Reset the article data when closing the modal
    },
    


    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    },
     formatTime(dateString) {
      const date = new Date(dateString);
      let hours = date.getUTCHours();
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      
      hours = hours % 12 || 12; // Convert to 12-hour format
      return `${hours}:${minutes} ${period}`;
    },

  },
};
</script> -->

<template>
  <DashboardLayout>
    <div>
      <h2 class="admin-table-title my-2">Doctor’s Sign-up Log {{doctorrequests?.length}}</h2>
      
      <!-- AdminTable component, render message when doctorrequests.length === 0 -->
      <AdminTable :columns="COLUMNS" :loading="loading">
        <template v-if="doctorrequests.length === 0">
          <tr>
            <td :colspan="COLUMNS.length" class="no-data-text my-2">
              <v-icon large class="no-data-icon">mdi-alert-circle-outline</v-icon>
              <div>
                No Record Found for Doctors Request
              </div>
            
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="table-row" v-for="(request, i) in doctorrequests" :key="i">
            <td>{{ formatDate(request?.created_date) }}</td>
            <td>{{ request?.email }}</td>
            <td>{{ request?.phone_number }}</td>
            <td>{{ formatTime(request?.created_date) }}</td>
            <td>
              <primary-button
                @click="showDetails(request)"
                :color="'#BACBEC'"
                :class="'blue--text'"
              >View</primary-button>
            </td>
          </tr>
        </template>
      </AdminTable>

      <div class="my-4" v-if="doctorrequests.length > 0">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <div>
      <v-dialog max-width="580px" v-model="showdetails" :key="modalkey">
        <DoctorRequest
          :doctor="doctor"
          @closeModal="closeModal"
        />
      </v-dialog>
    </div>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import DoctorRequest from "@/elements/Modals/DoctorRequest.vue";

export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
    PrimaryButton,
    DoctorRequest,
  },
  data: () => ({
    COLUMNS: ["Date", "E-mail", "Phone Number", "Time", "Action"],
    menuloading: false,
    showdetails: false,
    doctor: {},
    modalkey: 0,
  }),

  computed: {
    ...mapState("request", {
      loading: (state) => state.loading,
      doctorrequests: (state) => state.doctorrequests,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
  },

  created() {
    this.$store.dispatch("request/fetchDoctorRequests");
  },

  methods: {
    setPagination(e) {
      this.$store.dispatch("request/handlePagination", e);
    },

    showDetails(e) {
      this.doctor = { ...e };
      this.showdetails = true;
      this.modalkey++;
    },

    closeModal() {
      this.showdetails = false;
      this.doctor = {};
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    },

    formatTime(dateString) {
      const date = new Date(dateString);
      let hours = date.getUTCHours();
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12 || 12; // Convert to 12-hour format
      return `${hours}:${minutes} ${period}`;
    },
  },
};
</script>

<style scoped>
.no-data-text {
  text-align: center;
  font-size: 16px;
  color: #666;
  padding: 20px 0;
}


.no-data-icon {
  color: #BACBEC;
  margin-right: 8px;
}
</style>
